/*
import React, { useEffect, useRef, useState } from 'react';
import * as tf from '@tensorflow/tfjs'
import {load} from '@tensorflow-models/handpose'
import Webcam from 'react-webcam';
import { drawHand } from './utils/utils';

function App() {
  const [ctx,setCtx] = useState()
  const canvasRef = useRef()
  const webcamRef = useRef()

  
  useEffect(()=>{
    if(ctx){
      runHandpose()
    }
  },[ctx])

  useEffect(()=>{
    console.log("object");
    if(canvasRef.current){

      const ctx = canvasRef.current.getContext("2d")
      setCtx(ctx)
      
      
    }
    return()=>setCtx(null)
  },[canvasRef])
  const runHandpose =async ()=>{
    try {
      const net = await load();
      setInterval(()=>{detect(net)},10)
      
    } catch (err) {
      console.log(err);
    }
  }
  const videoConstraints = {
    facingMode: "environment"
  };
  const detect = async(net)=>{
    if(typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4){
      const video = webcamRef.current.video
      const videoWidth = webcamRef.current.video.videoWidth
      const videoHeight = webcamRef.current.video.videoHeight

      webcamRef.current.video.width = videoWidth
      webcamRef.current.video.height = videoHeight

      canvasRef.current.width = videoWidth
      canvasRef.current.height = videoHeight

      const hand = await net.estimateHands(video)
      // console.log(hand);

      // console.log(ctx);
      drawHand(hand,ctx)
    }
  }
  
  return (
    <div className="App">
     <canvas ref={canvasRef}
     style={{
      position:"absolute",
      marginLeft:'auto',
      marginRight:"auto",
      left:0,
      right:0,
      textAlign:'center',
      zIndex:10,
      width:'400px',
      height:"500px"
    }}
     
     ></canvas>
     <Webcam ref={webcamRef} 
     videoConstraints={videoConstraints}
      style={{
        position:"absolute",
        marginLeft:'auto',
        marginRight:"auto",
        left:0,
        right:0,
        textAlign:'center',
        zIndex:9,
        width:'400px',
        height:"500px"
      }}
     />
    </div>
  );
}

export default App;


*/


import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as tf from '@tensorflow/tfjs'
import * as handPoseDetection from '@tensorflow-models/hand-pose-detection';

import Webcam from 'react-webcam';
import { MdCamera, MdCameraswitch } from "react-icons/md";
import { drawHand } from './utils/utils';


import "./App.css"
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER
};
function App() {
  const [ready, setReady] = useState(false)
  const [ctxC, setCtxc] = useState(null)
  const canvasRef = useRef()
  const webcamRef = useRef()
  const downloadImgRef = useRef()
  const canvasForDownloadRef = useRef()

  const interVal = useRef()

  useEffect(() => {
    runHandpose()

  }, [ready])
  const runHandpose = async () => {
    try {
      // const net = await load();
      if (ready) {

        const model = handPoseDetection.SupportedModels.MediaPipeHands;
        const detectorConfig = {
          runtime: 'mediapipe', // or 'tfjs',
          solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/hands',
          modelType: 'full'
        }
        const detector = await handPoseDetection.createDetector(model, detectorConfig);

        // console.log(ctx);
        const ctx = canvasRef.current.getContext("2d")
        setCtxc(ctx)
        interVal.current = setInterval(() => {
          detect(detector, ctx)
          // console.log(hands);
        }, 10)


      } else {
        clearInterval(interVal.current)
      }


      // console.log(hands);
    } catch (err) {
      console.log(err);
    }
  }

  const detect = async (detector, ctx) => {
    if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
      const video = webcamRef.current.video
      const videoWidth = webcamRef.current.video.videoWidth
      const videoHeight = webcamRef.current.video.videoHeight

      webcamRef.current.video.width = videoWidth
      webcamRef.current.video.height = videoHeight

      canvasRef.current.width = videoWidth
      canvasRef.current.height = videoHeight

      const hands = await detector.estimateHands(video);

      // console.log(ctx);
      drawHand(hands, ctx)
    }
  }
  const takePhoto = useCallback(
    () => {
      const ctx = canvasForDownloadRef.current.getContext("2d")
      const videoWidth = webcamRef.current.video.videoWidth
      const videoHeight = webcamRef.current.video.videoHeight
      canvasForDownloadRef.current.width = videoWidth
      canvasForDownloadRef.current.height = videoHeight
      const imageSrc = webcamRef.current.getScreenshot();
      const getCanvasImage = canvasRef.current.toDataURL("image/png");

      const imageforcanvas1 = new Image()
      imageforcanvas1.src=imageSrc
      imageforcanvas1.onload = function(){
        ctx.drawImage(imageforcanvas1, 0, 0);
        const imageforcanvas2 = new Image()
        imageforcanvas2.src=getCanvasImage
        imageforcanvas2.onload = function(){
          ctx.drawImage(imageforcanvas2, 0, 0);
          const imgToDownload = canvasForDownloadRef.current.toDataURL("image/png");
          
          downloadImgRef.current.href=imgToDownload
          downloadImgRef.current.download=Math.floor(Math.random()*10000)
          downloadImgRef.current.click()
        }

      }

      

    },
    [webcamRef,canvasForDownloadRef.current]
  )

  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setReady(false)
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);
  return (
    
    <div className="App" style={{
      width: '100%',
      height: "100vh",
      position: "relative"
    }}>
      <canvas ref={canvasRef}
        style={{
          position: "absolute",
          marginLeft: 'auto',
          marginRight: "auto",
          left: 0,
          right: 0,
          backgroundColor:'transparent',
          textAlign: 'center',
          top: 0,
          zIndex: 10,
          width: '100%',
          height: "100vh"
        }}

      ></canvas>
      <canvas ref={canvasForDownloadRef}
        style={{
          position: "absolute",
          marginLeft: 'auto',
          marginRight: "auto",
          left: 0,
          right: 0,
          backgroundColor:'transparent',
          textAlign: 'center',
          top: 0,
          zIndex: 1,
          width: '100%',
          height: "100vh"
        }}

      ></canvas>
      <Webcam ref={webcamRef}
        onUserMedia={(data) => setReady(data.active)}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          ...videoConstraints,
          width: window.innerHeight,
          height: window.innerWidth,
          facingMode
        }}
        width={window.innerHeight}
        height={window.innerWidth}
        style={{
          position: "absolute",
          marginLeft: 'auto',
          marginRight: "auto",
          left: 0,
          right: 0,
          top: 0,
          textAlign: 'center',
          zIndex: 9,
          height: "100vh",
          width: "100%",    
          objectPosition: "left",
          objectFit: "cover"

        }}
      />
      <div className='btn-wraper'>

        <button className='btn flex-center' onClick={handleClick}><MdCameraswitch /></button>
        <button className='btn flex-center' onClick={takePhoto}><MdCamera /></button>
        <button className='btn flex-center' style={{opacity:"0",pointerEvents:"none"}} ></button>
      </div>
      <a ref={downloadImgRef} style={{display:'none'}}></a>
    </div>
  );
}

export default App;
