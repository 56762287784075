// export const drawHand = (predictions,ctx)=>{
//     // console.log(ctx);
//     if(predictions.length>0){
//         predictions.forEach(prediction => {
//             const landmarks = prediction.landmarks
//             for (let i = 0; i < landmarks.length; i++) {
//                 const x = landmarks[i][0];
//                 const y = landmarks[i][1];

//                 ctx.beginPath()
//                 ctx.arc(x,y,5,0,3*Math.PI)
//                 ctx.fillStyle= "indigo"
//                 ctx.fill()

//             }
//         });
//     }
// }
export const drawHand = (hands, ctx) => {
    // console.log(ctx);
    if (hands.length > 0) {
        hands.forEach(hand => {
            ctx.font = "40px Arial";

            const landmarks = hand.keypoints
            if (hand.handedness === "Left") {
                ctx.fillStyle = "red"
                ctx.fillText("راست", landmarks[0].x+10, landmarks[0].y);
            } else {
                ctx.fillStyle = "blue"
                ctx.fillText("چپ", landmarks[0].x+10, landmarks[0].y);
            }
            for (let i = 0; i < landmarks.length; i++) {
                const x = landmarks[i].x;
                const y = landmarks[i].y;

                ctx.beginPath()
                ctx.arc(x, y, 5, 0, 3 * Math.PI)

                ctx.fill()

            }
        });
    }
}